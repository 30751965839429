#walletContainer {
    padding-top: 80px;
}

.wallet-token-image {
    background: #333;
    position: absolute;
    width: 100%;
    top: 10%;
    height: 60%;
    z-index: 5;
    overflow: hidden;
    background-size: contain;
}

.wallet-token-image .percent {
    position: absolute;
    width: 100%;
    height: 19%;
    top: 32.5%;
    left: 0;
    font-family: subspace;
    text-align: center;
    font-size: 2rem;
    line-height: 4rem;
    vertical-align: middle;
    color: white;
}

.padd {
    min-height: 80vh;
}