.nft-token.embed{
    width:100%;
    padding-top: 135%;
    position: relative;
    perspective: 35cm;
    font-size: 1rem;
}

.flipper{
    position: absolute;
    top: 0;
    left:0;
    width:100%;
    height:100%;
    transform: rotateY(0deg);
    transform-style: preserve-3d;
    perspective-origin: center;
    transition: all 0.7s ease-in-out;
}

.flipper.flipped{
    transform: rotateY(180deg);
}

.flipper .face{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    border-radius: 1rem;
    backface-visibility: hidden;
    -webkit-backface-visibility: hidden;
}


.flipper .face.front{
    transform: translateZ(1px);
    
}

.flipper .face.back{
    transform: rotateY(180deg) translateZ(1px);
}



.card-icon{
    position: absolute;
    top:1em;
    background: red;
    padding: 0.25em;
    border-radius: 0.25em;
    cursor: pointer;
    z-index:1000;
}

.card-icon img {
    max-width: 32px;
}

.card-icon.left{
    left: 1em;
}

.card-icon.right{
    right: 1em;
}



.card-descr h5 {
    color:#5b5b5b;
    font-size:1.1em;
}

.embed .trait{
    max-width: 96px;
    max-height: 86px;
}

.trait-container img{
    max-width: 64px;
    margin-right:1em;
    margin-bottom: 1em;
    cursor: pointer;
}

.trait-container img.disabled{
    opacity: 0.5;
}

.embed .trait-details h5{
    font-size: 1.6em;
    line-height: 1.2em;
}

.embed .trait-details p{
    font-size: 0.75em;
}

.embed .trait-details .trait{
    max-width: 140px;
    max-height: 120px;
}

.embed .details-content{
    font-size: 0.7em;
}

.embed .details-content h3{
    font-size: 2.1em;
}

.f-link{
    color:red;
    font-weight: bold;
    cursor: pointer;
    transition: color 0.3s ease-out;
} 

.f-link:hover{
    color:darkred; 
}


.embed .face.back .fullscreen-button .fs{
    opacity: 0.1;
}
.embed .face.back .fullscreen-button:hover .fs{
    opacity: 1.0;
}

.face:hover .fullscreen-button {
    opacity: 1;
}


.face.front::after{
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 10;
    top: 0;
    left: 100%;
    display: block;
    background: linear-gradient(112deg, rgba(255,255,255,0) 35%, rgba(255,255,255,0.30) 50%, rgba(255,255,255,0) 65%);
    background-position-x: 0;
    background-repeat: no-repeat;
    transition: all ease-in-out 0.35s; 
    pointer-events: none;
}

.face.back::after{
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 2;
    top: 0;
    left: 100%;
    display: block;
    background: linear-gradient(112deg, rgba(255,255,255,0) 35%, rgba(255,255,255,0.30) 50%, rgba(255,255,255,0) 65%);
    background-position-x: 0;
    background-repeat: no-repeat;
    transition: all ease-in-out 0.35s;
    transition-delay: 0.35s;
    pointer-events: none;
}



.flipped .face.front::after{
    left:-50%;
}

.flipped .face.back::after{
    left:-90%;
}

.embed.c-xlarge {
    font-size: 2rem;
}

.embed.c-large {
    font-size: 1.5rem;
}
