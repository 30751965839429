.image-view {
    position: fixed;
    top: 0;
    bottom: 100%;
    left: 0;
    right: 0;
    z-index: 1223;
    text-align: center;
    background-color: rgba(0,0,0,0.8);
    transition: bottom 0.7s cubic-bezier(0.175, 0.885, 0.32, 1.275);;
    overflow:hidden;
}

.image-view img{
    height: 100%;
    width: 100%;
    object-fit: scale-down;
}
.image-view.show{
    bottom: 0;
}

.image-view .close-btn{
    position: absolute;
    top: 16px;
    right: 16px;
    width: 16px;
    z-index: 10;
}
.image-view .view-card-video{
    max-height: 100vh;
}