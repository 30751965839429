
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;700&display=swap');

@font-face {
  font-family: 'subspace';
  src: local('subspace'), url(./Subspace.otf) format('opentype');
}
@font-face {
  font-family: 'teko';
  src: local('teko'), url(./assets/fonts/Teko-SemiBold.ttf) format('truetype');
}

.thumbnail{
  position : absolute;
  top : 0 ;
  z-index : -5000;
  width : 0px;
  height : 0px;
}
.thumbnail img{
      object-fit : cover;
      width : 0px;
      height : 0px;
}

.bn-onboard-custom{
  z-index: 2323;
}

.clickable {
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}

.clickable:hover {
  color:#be1fda;
}


html, body{
  font-family: 'Poppins', sans-serif;
  font-weight: 300;
  background: #1B1E25;
  color: #969da3;
  height: 100%;
}

h1,h2,h3,h4,h5{
  font-weight: 700;
  color: #ffffff;
}
h4,h5{
  font-weight: 500;
  color: #ffffff;
}
#root{
  height: 100%;
}
#application {
  padding-top: 0;
  background: none;
  position: relative;
  min-height: 100%;
  /* padding-bottom: 100px; */
}
.btn.round {
  border-radius:0.6rem;
}
.btn-green {
  background-color: #94E316;
  color: black;
}

.btn-peach {
  border-color:#be1fda;
  border: solid 3px #be1fda;
  background-color: #be1fda;
  color: black;
  white-space: pre-wrap;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.btn-green.focus, .btn-green:focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(148,227,22,.25);
}

.btn-peach.focus, .btn-peach:focus {
 outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(189, 92, 153, 0.25);
}

.btn-outline {
  background: none;

  border:solid 3px;
}

.btn-outline.btn-peach{
  border-color:#be1fda;
  color:white;
}

.btn-outline.btn-peach:hover{
  background-color:#be1fda;
  color:#fff;
}

.btn-peach:hover{
  background-color:#1B1E25;
  color:#be1fda;
}

.modal-container {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0;
  background: rgba(0, 0, 0, 0.6);
}

.modal-content {
  background-color: #3f3d3a;
}

.modal-header {
  border-bottom: 1px solid #949393;
}
.modal-footer {
  border-top: 1px solid #949393;
}

.close {
  color: #fff;
  text-shadow: 0 1px 0 #333;
  opacity: .5;
}

.toastContainer {
  z-index:2000;
}

.text-peach {
  color: #be1fda;
}

.text-lilac {
  color: #771197;
}

.bold-text{
  font-weight: 400;
}

/* modal overrides... */
.web3modal-provider-container{
  padding: 4px !important;
}
.web3modal-provider-icon{
  width: 32px !important;
  height: 32px !important;
}
.web3modal-provider-name{
  font-size: 1.1em !important;
  margin-top: 0.3em !important;
}
.web3modal-provider-description{
  font-size: 0.9em !important;
  margin: 0.2em 0px !important;
}
.web3modal-modal-card{
  max-width:710px !important;
}