#forgeContainer {
  min-height: 100vh;
  background-color: #161920;
  display: flex;
  flex-direction: column;
  position: relative;
}

#forgeSection {
  background-color: #131519;
  min-height: 360px;
  padding-top: 80px;
  padding-bottom: 20px;
}

#cardsSection {
  min-height: 40vh;
  background-color: #161920;
}
.upload-card-container {
  position: relative;
  max-height: 100%;
  padding-top: 136.2%;
  border-radius: 20px;
  overflow: hidden;
  background-color: #161920;
}
.upload-card-container .remove-button {
  position: absolute;
  height: fit-content;
  width: fit-content;
  cursor: pointer;
  margin: auto;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}
.upload-card-container .remove-button:active {
  opacity: 0.7;
  transform: translate(0, 1px);
}
.upload-card-container .plus-button {
  position: absolute;
  height: fit-content;
  margin: auto;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}
.upload-card-container .bottom-text {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 20%;
  margin: auto;
  width: 70%;
  font-size: 0.8rem;
}
.upload-card-container .overlay-forging {
  position: absolute;
  background-color: rgb(0, 0, 0, 0.8);
  display: flex;
  justify-items: center;
  place-content: center;
  border-radius: 20px;
  height: 100%;
  width: 100%;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}
.upload-card-container .overlay-forging h4 {
  align-self: center;
}
.upload-card-container .fullscreen-button {
  display: none !important;
}

.upload-card-container .card-type {
  font-size: 0.8rem;
}

#infoBtn {
  height: 30px;
}
#infoBtn:hover {
  cursor: pointer;
  opacity: 0.7;
}

.info-popup {
  position: relative;
  background-color: #000;
  text-align: center;
  border-radius: 20px;
  padding: 30px 20px;
  max-width: 350px;
}
.popup-arrow {
  display: none;
}
.info-close-button {
  height: 20px;
  position: absolute;
  right: 10%;
  top: 10%;
}
.info-close-button:hover {
  cursor: pointer;
  opacity: 0.7;
}

#forgeNowBtn {
  /* animation: buttonGlow 4s ease infinite; */
}
.hide {
  opacity: 0 !important;
  min-height: 0 !important;
  height: 0px !important;
  overflow: hidden;
  transition: height 500ms 500ms, opacity 500ms 0ms;
}
@keyframes buttonGlow {
  0% {
    box-shadow: none;
  }
  50% {
    box-shadow: 0 0 20px 5px #781879c5;
  }
  0% {
    box-shadow: none;
  }
}

.card-text {
  margin-top: 5px;
  margin-left: 5px;
  font-size: 14px;
}

#layerSection {
  opacity: 1;
  min-height: 300px;
  background-color: #131519;
  transition: opacity 500ms ease-in;
}

.layer-card-container {
  cursor: pointer;
  position: relative;
  max-height: 100%;
  padding-top: 136.2%;
  margin-bottom: 20px;
  padding-bottom: 5px;
  margin-top: 20px;
  border-radius: 20px;
  background-color: rgb(255, 255, 255, 0.5);
}
.layer-card-container.selected {
  box-shadow: 0 0 0 4px #be1fda;
}

.layer-card-container img {
  top: 0;
  left: 0;
  position: absolute;
  border-radius: 20px;
  height: 100%;
  max-width: 100%;
  object-fit: cover;
}
.layer-card-container .layer-name {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  width: fit-content;
  font-size: 1rem;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 10px;
  padding: 5px 10px;
  background-color: #161920;
  border-radius: 10px;
  color: white;
  transform: translateZ(30px);
}

.merge-card-container {
  position: relative;
  max-height: 100%;
  padding-top: 136.2%;
  margin-bottom: 20px;
  margin-top: 20px;
  border-radius: 20px;
  background-color: rgb(255, 255, 255, 0.5);
}

.merge-card-container img {
  top: 0;
  left: 0;
  position: absolute;
  border-radius: 20px;
  height: 100%;
  max-width: 100%;
  width: 100%;
  object-fit: cover;
}

.trait-card-container {
  cursor: pointer;
  position: relative;
  max-height: 100%;
  padding-top: 136.2%;
  margin-bottom: 20px;
  padding-bottom: 5px;
  margin-top: 20px;
  border-radius: 20px;
  background-color: rgb(255, 255, 255, 0.5);
}

.trait-card-container.selected {
  box-shadow: 0 0 0 4px #be1fda;
}

.forge-loader {
  display: flex;
  flex-direction: column;
  z-index: 1000;
  position: fixed;
  background-color: rgb(0, 0, 0, 0.8);
  height: 100vh;
  width: 100%;
  place-content: center;
}
.forge-loader div {
  align-self: center;
  text-align: center;
}
#cardsSection .merge-card-container {
  margin-top: 0;
}
