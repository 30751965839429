.tbx-nav{ 
    background-color: #000208;
    box-shadow: 0 5px 0 rgba(0, 0, 0, 0.4);
    padding:0;
    min-height: 80px;
}

.tbx-nav .nav-link {
    color:#969da3;
    font-weight: 400;
}

.navbar-dark .navbar-nav .nav-link.active{
    color: #be1fda;
}

.dropdown-menu {
    background-color: #000;
    text-align: right;
}

p.disconnect {
    cursor: pointer;
    transition: color 0.3s ease-in-out;
    margin:0;
    font-size: 0.8rem;
    line-height: 0.3rem;
}

p.disconnect:hover {
    color: white;
}

.navbar-brand img{
    max-height: 40px;
}

.navbar .navbar-toggler{
    outline:none;
    border: none;
}

.connect-btn:hover{
    background: #771197;
    border-color:#771197;
    color: white;
}

@media (max-width: 992px) {
    .dropdown-menu {
        background-color: none;
        margin:0;
        padding: 0;
    }
}